import { FunctionComponent, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { parseXLSX, Rows } from "utils/parseXLSX";
import { FilePlus } from "phosphor-react";
import { css, styled } from "stitches.config";
import { FullLayout } from "./FullLayout";
import { useAlert } from "react-alert";

type Props = {
  onFileLoaded: (file: Rows) => void;
};

export const FileDrop: FunctionComponent<Props> = ({ onFileLoaded }) => {
  const alert = useAlert();

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      const firstFile = acceptedFiles[0];
      if (!firstFile) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target?.result;
        if (typeof bstr !== "string") {
          return;
        }
        try {
          const rows = parseXLSX(bstr);
          if (rows.length === 0) {
            alert.info("Le fichier est vide");
            return;
          }
          onFileLoaded(rows);
        } catch (error) {
          console.error(error);
          alert.error("Impossible de charger le fichier");
        }
      };
      reader.readAsBinaryString(firstFile);
    },
    [alert, onFileLoaded]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  return (
    <FullLayout
      content={
        <Wrapper>
          <UploadZone
            {...getRootProps()}
            className={isDragActive ? ActiveZone : ""}
          >
            <input {...getInputProps()} />
            <FilePlus size={68} weight="thin" color="currentColor" />
            <Text>Drop a file here</Text>
          </UploadZone>
        </Wrapper>
      }
    />
  );
};

const ActiveZone = css({
  backgroundColor: "$red500",
  color: "$white",
});

const Wrapper = styled.div({
  flex: 1,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
});

const UploadZone = styled.div({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  minWidth: "$40",
  minHeight: "$40",
  backgroundColor: "$transparentRed",
  padding: "$06 $10",
  borderRadius: "$big",
  cursor: "pointer",
  color: "$grey900",
  ":hover": {
    backgroundColor: "$red500",
    color: "$white",
  },
});

const Text = styled.p({
  marginTop: "$02",
  fontHeight: "$12",
});
