import { memo } from "react";
import { Header } from "./Header";
import { styled } from "stitches.config";

type Props = {
  content: React.ReactNode | null;
  headerRightAction?: React.ReactNode | null;
};

export const FullLayout = memo<Props>(({ content, headerRightAction }) => {
  return (
    <AppWrapper>
      <Header rightAction={headerRightAction} />
      {content}
    </AppWrapper>
  );
});

const AppWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  alignSelf: "center",
  backgroundColor: "$white",
  borderRadius: "$medium",
  height: `calc(100% - 24px)`,
  width: `calc(100% - 24px)`,
  boxShadow: "$soft",
  overflow: "hidden",
});
