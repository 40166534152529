import React from "react";
import { App } from "./App";
import { positions, Provider } from "react-alert";
import { AlertTemplate } from "./AlertTemplate";

export function Root() {
  return (
    <Provider
      template={AlertTemplate}
      timeout={20000}
      position={positions.BOTTOM_CENTER}
      containerStyle={{
        marginBottom: 16,
      }}
    >
      <App />
    </Provider>
  );
}
