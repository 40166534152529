import * as z from "zod";
import XLSX from "xlsx";
import { format } from "date-fns";

const DateObjValidator = z.object({
  d: z.number(),
  m: z.number(),
  y: z.number(),
});

export type DateObj = z.infer<typeof DateObjValidator>;

export type Row = z.infer<typeof RowValidator>;
const RowValidator = z.object({
  category: z.string(),
  date: DateObjValidator,
  description: z.string(),
  amount: z.number(),
});

export type Rows = z.infer<typeof RowsValidator>;
const RowsValidator = z.array(RowValidator);

export function parseXLSX(bstr: string): Rows {
  const wb = XLSX.read(bstr, { type: "binary" });
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  const data = XLSX.utils.sheet_to_json(ws, { range: 2 });
  const rowsUnsafe = data.map((rawRow: any) => {
    return {
      category: rawRow["Categorie operation"],
      date: parseDate(rawRow["Date operation"]),
      description: rawRow["Libelle operation"],
      amount: rawRow["Montant operation"],
    };
  });
  const rows = RowsValidator.parse(rowsUnsafe);
  return rows;
}

function parseDate(raw: unknown): DateObj {
  if (typeof raw === "number") {
    const date = new Date(Math.round((raw - 25569) * 86400 * 1000));
    raw = format(date, "dd-MM-yyyy");
  }
  if (typeof raw === "string") {
    const [d, m, y] = raw.split("-");
    return {
      d: parseInt(d, 10),
      m: parseInt(m, 10),
      y: parseInt(y, 10),
    };
  }
  throw new Error("Invalid date");
}
