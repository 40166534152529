import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { DateObj, Row, Rows } from "utils/parseXLSX";
import { ScrollFlex } from "./ScrollFlex";
import {
  CellProps,
  Column,
  Renderer,
  useTable,
  useBlockLayout,
} from "react-table";
import useComponentSize from "@rehooks/component-size";
import { css, styled } from "stitches.config";
import { FullLayout } from "./FullLayout";
import { Square, CheckSquare } from "phosphor-react";
import { dataToSheetString } from "utils/dataToSheetString";
import * as clipboard from "clipboard-polyfill/text";
import { useAlert } from "react-alert";

type Props = {
  data: Rows;
};

type TableItem = Row & { selected: boolean };

const CHECKBOX_WIDTH = 80;
const DATE_WIDTH = 120;
const AMOUNT_WIDTH = 150;

const Table = styled.div({
  flex: 1,
  borderSpacing: 0,
  display: "flex",
  flexDirection: "column",
});

const THead = styled.div({
  paddingRight: "$04",
  paddingLeft: "$04",
  backgroundColor: "$red400",
  borderTopStyle: "solid",
  borderColor: "$transparentDark",
  borderWidth: "$small",
});

const THeadInner = styled.div({});

const TBody = styled.div({
  paddingRight: "$04",
  paddingLeft: "$04",
});

const TRow = styled.div({
  borderBottomWidth: "$thin",
  borderColor: "$transparentRed",
  borderBottomStyle: "solid",
});

const THCell = styled.div({
  fontFamily: "$spaceGrotesk",
  fontWeight: "$600",
  padding: "$04 $02",
  color: "$white",
});

const RIGHT_ALIGN = css({
  textAlign: "right",
});

const TCell = styled.div({
  position: "relative",
});

const TCellInner = styled.div({
  width: "100%",
  height: "100%",
  padding: "$02",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const DateText = styled.p({
  textAlign: "left",
  fontWeight: "$600",
  flex: 1,
});

const AmountText = styled.p({
  textAlign: "right",
  fontWeight: "$600",
  flex: 1,
});

function dateObjToString(obj: DateObj): string {
  return (
    obj.d.toFixed(0).padStart(2, "0") +
    "/" +
    obj.m.toFixed(0).padStart(2, "0") +
    "/" +
    obj.y
  );
}

const DateCell: Renderer<CellProps<TableItem, DateObj>> = ({ value }) => {
  return <DateText>{dateObjToString(value)}</DateText>;
};

const CheckboxWrapper = styled.div({
  cursor: "pointer",
});

const CheckboxCell: Renderer<CellProps<TableItem, DateObj>> = ({
  value,
  column,
  row,
}) => {
  const Icon = value ? CheckSquare : Square;
  return (
    <CheckboxWrapper
      onClick={(e) => {
        if (e.altKey || e.shiftKey) {
          (column as any).onAltClick(row.index);
        } else {
          (column as any).onClick(row.index);
        }
      }}
    >
      <Icon size={28} />
    </CheckboxWrapper>
  );
};

const MONTH_MAP = {
  1: "01-JANVIER",
  2: "02-FÉVRIER",
  3: "03-MARS",
  4: "04-AVRIL",
  5: "05-MAI",
  6: "06-JUIN",
  7: "07-JUILLET",
  8: "08-AOÛT",
  9: "09-SEPTEMBRE",
  10: "10-OCTOBRE",
  11: "11-NOVEMBRE",
  12: "12-DÉCEMBRE",
} as const;

const formatter = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
});

const AmountCell: Renderer<CellProps<TableItem, number>> = ({ value }) => {
  if (value === null) {
    return null;
  }
  return <AmountText>{formatter.format(value)}</AmountText>;
};

const ExportButton = styled.button({
  display: "flex",
  border: "none",
  borderRadius: "$medium",
  backgroundColor: "$transparentLight",
  flexDirection: "row",
  alignItems: "center",
  color: "$white",
  fontHeight: "$12",
  alignSelf: "center",
  marginRight: "$02",
  padding: "$01 $04",
  cursor: "pointer",
});

export const RowsTable: FunctionComponent<Props> = ({ data }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const size = useComponentSize(contentRef);

  const [dataWithSelected, setDataWithSelected] = useState(() =>
    data.map((row): TableItem => ({ ...row, selected: false })).reverse()
  );

  const toggleItem = useCallback((index: number) => {
    setDataWithSelected((prev) => {
      const copy = [...prev];
      copy[index] = { ...prev[index], selected: !prev[index].selected };
      return copy;
    });
  }, []);

  const toggleItemsAfter = useCallback((index: number) => {
    setDataWithSelected((prev) => {
      return prev.map((row, i) =>
        i >= index ? { ...row, selected: true } : row
      );
    });
  }, []);

  const columns = React.useMemo((): Array<
    Column<TableItem> & {
      alignRight?: boolean;
      onClick?: any;
      onAltClick?: any;
    }
  > => {
    const remainingWidth = Math.max(
      300,
      size.width - (DATE_WIDTH + AMOUNT_WIDTH + AMOUNT_WIDTH + CHECKBOX_WIDTH)
    );

    return [
      {
        Header: "",
        accessor: "selected",
        Cell: CheckboxCell,
        width: CHECKBOX_WIDTH,
        onClick: toggleItem,
        onAltClick: toggleItemsAfter,
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: DateCell,
        width: DATE_WIDTH,
      },
      {
        Header: "Description",
        accessor: "description",
        width: remainingWidth * 0.6,
      },
      {
        Header: "Category",
        accessor: "category",
        width: remainingWidth * 0.4,
      },
      {
        Header: "Revenus",
        accessor: (row) => (row.amount > 0 ? row.amount : null),
        id: "income",
        width: AMOUNT_WIDTH,
        Cell: AmountCell,
        alignRight: true,
      },
      {
        Header: "Depenses",
        accessor: (row) => (row.amount < 0 ? -row.amount : null),
        id: "expenses",
        width: AMOUNT_WIDTH,
        Cell: AmountCell,
        alignRight: true,
      },
    ];
  }, [size.width, toggleItem, toggleItemsAfter]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<TableItem>({ columns, data: dataWithSelected }, useBlockLayout);

  const selectedItems = useMemo(() => {
    return dataWithSelected.filter((v) => v.selected);
  }, [dataWithSelected]);

  const alert = useAlert();

  return (
    <FullLayout
      headerRightAction={
        selectedItems.length === 0 ? null : (
          <ExportButton
            onClick={async () => {
              const data: Array<Array<string>> = selectedItems.map((row) => {
                return [
                  // Libelle
                  row.description,
                  // Description
                  "",
                  // Motif
                  row.category,
                  // Revenus
                  row.amount > 0
                    ? Math.abs(row.amount).toFixed(2).replace(".", ",")
                    : "",
                  // Dépenses
                  row.amount < 0
                    ? Math.abs(row.amount).toFixed(2).replace(".", ",")
                    : "",
                  // Date
                  dateObjToString(row.date),
                  // Mois
                  (MONTH_MAP as any)[row.date.m],
                ];
              });
              const resultStr = dataToSheetString(data);
              await clipboard.writeText(resultStr);
              alert.success("Données copiées dans le clipboard !");
            }}
          >
            Export
          </ExportButton>
        )
      }
      content={
        <Table {...getTableProps()}>
          <THead>
            <THeadInner ref={contentRef}>
              {headerGroups.map((headerGroup) => (
                <TRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <THCell
                      {...column.getHeaderProps()}
                      className={(column as any).alignRight ? RIGHT_ALIGN : ""}
                    >
                      {column.render("Header")}
                    </THCell>
                  ))}
                </TRow>
              ))}
            </THeadInner>
          </THead>

          <ScrollFlex>
            <TBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TCell {...cell.getCellProps()}>
                          <TCellInner>{cell.render("Cell")}</TCellInner>
                        </TCell>
                      );
                    })}
                  </TRow>
                );
              })}
            </TBody>
          </ScrollFlex>
        </Table>
      }
    />
  );
};
