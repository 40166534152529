import { FunctionComponent } from "react";
import { css, styled } from "stitches.config";
import { CurrencyCircleDollar } from "phosphor-react";

type Props = {
  rightAction?: React.ReactNode | null;
  leftAction?: React.ReactNode | null;
};

export const Header: FunctionComponent<Props> = ({
  leftAction,
  rightAction,
}) => {
  return (
    <HeaderEl>
      <div className={css({ flex: 1, width: "10%" })}>{leftAction}</div>
      <Title>
        <CurrencyCircleDollar size={30} />
        <div className={css({ width: "$02" })} />
        Budget Helper
      </Title>
      <div
        className={css({
          flex: 1,
          display: "flex",
          width: "10%",
          flexDirection: "row-reverse",
        })}
      >
        {rightAction}
      </div>
    </HeaderEl>
  );
};

const HeaderEl = styled.header({
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  justifyContent: "space-between",
  textAlign: "center",
  height: "$20",
  marginBottom: 0,
  backgroundColor: "$red400",
  color: "$white",
});

const Title = styled.h1({
  fontHeight: "$20",
  margin: "0",
  display: "flex",
  alignSelf: "center",
  flexDirection: "row",
  alignItems: "center",
  paddingRight: "$02",
  color: "$white",
  wordWrap: "unset",
});
