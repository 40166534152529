import { FunctionComponent, useState } from "react";
import { RowsTable } from "./RowsTable";
import { Rows } from "utils/parseXLSX";
import { FileDrop } from "./FileDrop";

export const App: FunctionComponent = () => {
  const [rows, setRows] = useState<null | Rows>(null);

  return rows === null ? (
    <FileDrop onFileLoaded={setRows} />
  ) : (
    <RowsTable data={rows} />
  );
};
