export function dataToSheetString(data: Array<Array<string>>): string {
  let str = "";
  for (let r = 0, rlen = data.length; r < rlen; r += 1) {
    for (let c = 0, clen = data[r].length; c < clen; c += 1) {
      if (c > 0) {
        str += "\t";
      }
      let val = data[r][c];
      if (typeof val === "string") {
        if (val.indexOf("\n") > -1) {
          str += '"' + val.replace(/"/g, '""') + '"';
        } else {
          str += val;
        }
      } else if (val === null || val === void 0) {
        //void 0 resolves to undefined
        str += "";
      } else {
        str += val;
      }
    }
    str += "\n";
  }
  return str;
}
